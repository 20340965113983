import Cookies from "js-cookie";

export const ReportObjCreator = (
  type: string,
  publicId: string,
  patientName: string,
  patientDOB: string,
  values: ReportFields[]
) => {
  const reportData: CreateReportRequest = {
    patientPublicId: publicId,
    name: type + "_" + patientName + "_" + patientDOB,
    type: type,
    reportFields: values,
  };
  return reportData;
};

export const clearCookies = () => {
  const allCookies = Cookies.get();
  for (const cookie in allCookies) {
    Cookies.remove(cookie, { path: "/" });
  }
};
