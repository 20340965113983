import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { StickyFooter } from "../footer/StickyFooter";
import NavigationBar from "../../navigations/Navigation/NavigationBar";
// import NavigationBar from "../Navigation/SideNavigationBar";
import Loading from "../loading/Loading";
import ScrollToTopButton from "../styleComponents/ScrollToTopButton";
import CustomSnackbar from "../snackbar/CustomSnackbar";

// import CustomSnackbar from "../snackbar/CustomSnackbar";

const Layout = () => {
  const auth: any = useSelector<AuthState>((state) => state.auth);
  const snackbar: any = useSelector<SnackbarState>((state) => state.snackbar);
  const loader: any = useSelector<LoaderState>((state) => state.loader);
  const { active, variant, message } = snackbar.snackbar;
  const { isLoading } = loader.loader;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        {auth.auth.allowNavigation && <NavigationBar />}

        <Outlet />

        <CustomSnackbar active={active} variant={variant} message={message} />
        {isLoading && <Loading />}

        <ScrollToTopButton />
        <StickyFooter />
      </Box>
    </>
  );
};

export default Layout;
