import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser, preLoginCheck } from "../../apis/auth";
import { login } from "../../redux/slice/authSlice";
import { addUserCredentials } from "../../redux/slice/userSlice";
import {
  Grid,
  Box,
  Button,
  CssBaseline,
  TextField,
  createTheme,
  ThemeProvider,
  Alert,
  Typography,
  Container,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { doneLoading, loading } from "../../redux/slice/loaderSlice";
import logo from "../../assets/logo/P4FertilityLogo_Full.png";
import TermsOfUse from "./TermsOfUse";
import { loginDetails, removeLoginDetails } from "../../redux/slice/loginSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { LOGIN_FAILED, SNACKBAR_ERROR } from "../../constants/Constants";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../apis/users";
import { clientDetails } from "../../redux/slice/clientSlice";

const theme = createTheme();

export const Login = React.memo<SignUp>(({ setIsSignUp, isSignUp }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [clientId, setClientId] = useState<string>("p4fertility");
  const getToken = async (username: string, password: string) => {
    const [res, error] = await loginUser({ username, password, clientId });
    if (res) {
      dispatch(login({ allowNavigation: true }));
      let { data } = await getUserDetails();

      let userRoles: string[] = [];
      for (const role of data.roles) {
        userRoles.push(role.name);
      }
      data.roles = userRoles;
      if (data.firstLogin) {
      }
      dispatch(addUserCredentials(data));
      dispatch(removeLoginDetails());
      dispatch(doneLoading());
      navigate("/dashboard", { replace: true });
    } else {
      dispatch(doneLoading());
      dispatch(
        activateSnackbar({
          active: true,
          message: error || LOGIN_FAILED,
          variant: SNACKBAR_ERROR,
        })
      );
    }
  };
  const handleSubmit = async () => {
    if (username && password) {
      dispatch(loading());
      const [response, error] = await preLoginCheck({
        username,
        password,
        clientId,
      });
      if (response) {
        dispatch(
          loginDetails({
            username: username,
            password: password,
            passwordReset: response.passwordReset,
            twoFactorReset: response.twoFactorReset,
          })
        );
        if (response.passwordReset) {
          navigate("/login/first-login", { replace: true });
        } else {
          if (response.twoFactorEnabled) {
            if (!response.passwordReset && response.twoFactorReset) {
              navigate("/login/two-factor-auth", { replace: true });
            } else {
              navigate("/login/two-factor-auth-code", { replace: true });
            }
          } else {
            getToken(username, password);
          }
        }
      } else if (error) {
        dispatch(doneLoading());
        setErrorText(error);
        console.log(errorText);
      }
    }
  };

  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const enteredValue = e.target.value;
    setUsername(e.target.value);
    if (enteredValue.includes("@")) {
      setError("Username cannot be email address.");
    } else {
      setError("");
    }
    setUsername(enteredValue);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  useEffect(() => {
    dispatch(
      clientDetails({
        clientId: "p4fertility",
      })
    );
    setClientId("p4fertility");
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            marginBottom={3}
            style={{ textAlign: "center" }}
          >
            <img alt="logo" src={logo} width={"75%"} />
          </Typography>
          {errorText && <Alert severity="error">{errorText}</Alert>}
          <Box sx={{ mt: 1 }}>
            <TextField
              sx={{ borderRadius: 4 }}
              className="login-input"
              margin="normal"
              fullWidth
              id="username"
              label="Username"
              name="username"
              onChange={handleUsernameChange}
              autoComplete="email"
              autoFocus
              color={error ? "error" : "primary"}
              helperText={<div style={{ color: "red" }}>{error}</div>}
            />
            {/* {error && <Alert severity="error">{error}</Alert>} */}
            <TextField
              className="login-input"
              margin="normal"
              fullWidth
              name="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label="Password"
            />

            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              sx={{ mt: 3, mb: 2 }}
            >
              <b>Login</b>
            </Button>

            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2"> */}
                Forgot password? Please contact your Clinic Admin
                {/* </Link> */}
              </Grid>
              {/* <Grid item>
                <Link onClick={() => setIsSignUp(!isSignUp)} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
            </Grid>
          </Box>{" "}
        </Box>
      </Container>
      <Grid container xs={12} sm={8} md={6} xl={4} sx={{ margin: "0 auto" }}>
        <TermsOfUse />
      </Grid>
    </ThemeProvider>
  );
});
