import { Link, useLocation } from "react-router-dom";
import { BRAND_COLOR_LIGHT } from "../../constants/BrandConstants";
import ProfileAvatar from "./ProfileAvatar";
import { useSelector } from "react-redux";
import logo from "../../assets/logo/P4FertilityLogo_CRM.png";
import { AppBar, Box, Toolbar, styled } from "@mui/material";
import InitialAPICalls from "../../components/initialRendering/InitialAPICalls";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#ffffff",
  color: "#050505",
  minHeight: 0,
}));
const NavigationBar = () => {
  const userObject: any = useSelector<UserState>((state) => state.user);
  const location = useLocation();

  const isMenuSelected = (path: string) => {
    return location.pathname.includes(path);
  };

  const { firstName, lastName } = userObject.user.staff;
  return (
    <StyledAppBar position="static" elevation={0}>
      <InitialAPICalls />
      <Toolbar sx={{ justifyContent: "space-between", minHeight: 0 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <img src={logo} alt="logo" height="50" />
          </Link>
          <Box sx={{ mr: 2, ml: 2 }}>
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                fontWeight: "normal",
                color: isMenuSelected("/dashboard")
                  ? BRAND_COLOR_LIGHT
                  : "#808080",
                borderBottom: isMenuSelected("/dashboard")
                  ? `1px solid ${BRAND_COLOR_LIGHT}`
                  : "none",
              }}
            >
              Dashboard
            </Link>
          </Box>
          {/* <Box sx={{ mr: 2 }}>
            <Link
              to="/clinic-board"
              style={{
                textDecoration: "none",
                fontWeight: "normal",
                color: isMenuSelected("/clinic-board")
                  ? BRAND_COLOR_LIGHT
                  : "#808080",
                borderBottom: isMenuSelected("/clinic-board")
                  ? `1px solid ${BRAND_COLOR_LIGHT}`
                  : "none",
              }}
            >
              Clinic Board
            </Link>
          </Box> */}
          <Box sx={{ mr: 2 }}>
            <Link
              to="/patients"
              style={{
                textDecoration: "none",
                fontWeight: "normal",
                color: isMenuSelected("/patients")
                  ? BRAND_COLOR_LIGHT
                  : "#808080",
                borderBottom: isMenuSelected("/patients")
                  ? `1px solid ${BRAND_COLOR_LIGHT}`
                  : "none",
              }}
            >
              Patients
            </Link>
          </Box>
          <Box sx={{ mr: 2 }}>
            <Link
              to="/labs"
              style={{
                textDecoration: "none",
                fontWeight: "normal",
                color: isMenuSelected("/labs") ? BRAND_COLOR_LIGHT : "#808080",
                borderBottom: isMenuSelected("/labs")
                  ? `1px solid ${BRAND_COLOR_LIGHT}`
                  : "none",
              }}
            >
              Labs
            </Link>
          </Box>
          <Box sx={{ mr: 2 }}>
            <Link
              to="/admin-center"
              style={{
                textDecoration: "none",
                fontWeight: "normal",
                color: isMenuSelected("/admin-center")
                  ? BRAND_COLOR_LIGHT
                  : "#808080",
                borderBottom: isMenuSelected("/admin-center")
                  ? `1px solid ${BRAND_COLOR_LIGHT}`
                  : "none",
              }}
            >
              Admin Center
            </Link>
          </Box>
          {/* <Box sx={{ mr: 2 }}>
            <Link
              to="/drawboard"
              style={{
                textDecoration: "none",
                fontWeight: "normal",
                color: isMenuSelected("/drawboard")
                  ? BRAND_COLOR_LIGHT
                  : "#808080",
                borderBottom: isMenuSelected("/drawboard")
                  ? `1px solid ${BRAND_COLOR_LIGHT}`
                  : "none",
              }}>
              Sketchpad
            </Link>
          </Box> */}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ProfileAvatar
            user={{
              firstName: firstName,
              lastName: lastName,
              image: undefined,
            }}
          />
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default NavigationBar;
