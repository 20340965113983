import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearCookies } from "../../util/api.helpers";

const initialState: AuthState = {
  auth: {
    allowNavigation: false,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state: AuthState, action: PayloadAction<AuthEntities>) => {
      state.auth.allowNavigation = action.payload.allowNavigation;
    },
    logout: (state: AuthState) => {
      localStorage.clear();
      sessionStorage.clear();
      clearCookies();
      state = initialState;
    },
    getAuth: (state: AuthState) => {
      return state;
    },
  },
});

export default authSlice.reducer;
export const { login, logout, getAuth } = authSlice.actions;
